import React from "react";
import MetaTags from "react-meta-tags";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Container, Row} from "reactstrap";

/** import Mini Widget data */
import QuoteHistoricals from "./QuoteHistoricals";


const QuoteHistorical = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Quote | Jabhoon</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Historical" breadcrumbItem="Quote" />
          
          <Row>
            <QuoteHistoricals />
          </Row>



        </Container>
      </div>
    </React.Fragment>
  );
};

export default QuoteHistorical;
