import { format } from "d3-format";
import { timeFormat } from "d3-time-format";
import * as React from "react";

import {
    elderRay,
    ema,
    discontinuousTimeScaleProviderBuilder,
    Chart,
    ChartCanvas,
    CurrentCoordinate,
    BarSeries,
    CandlestickSeries,
    ElderRaySeries,
    LineSeries,
    MovingAverageTooltip,
    OHLCTooltip,
    SingleValueTooltip,
    lastVisibleItemBasedZoomAnchor,
    XAxis,
    YAxis,
    CrossHairCursor,
    EdgeIndicator,
    MouseCoordinateX,
    MouseCoordinateY,
    ZoomButtons,
    withDeviceRatio,
    withSize,
    MACDSeries,
    macd,
} from "react-financial-charts";

import { MACDTooltip } from "@react-financial-charts/tooltip";

import IOhlc from "../../../models/Ohlc/IOhlc";

interface CandlestickChartProps {
    data: IOhlc[];
    height: number;
    dateTimeFormat?: string;
    width: number;
    ratio: number;
    symbol: string;
}

class CandlestickChart extends React.Component<CandlestickChartProps> {

    margin = { left: 0, right: 44, top: 12, bottom: 24 };
    pricesDisplayFormat = format(".2f");
    xScaleProvider = discontinuousTimeScaleProviderBuilder().inputDateAccessor(
        (d: IOhlc) => d.date,
    );
    constructor(props: CandlestickChartProps) {
        super(props);
        this.state = {
            
        };
      }



    render() {
        const { data: initialData, dateTimeFormat = "%d %b", height, ratio, width, symbol} = this.props;
        const ema12 = ema()
            .id(1)
            .options({ windowSize: 12 })
            .merge((d: any, c: any) => {
                d.ema12 = c;
            })
            .accessor((d: any) => d.ema12);

        const ema26 = ema()
            .id(2)
            .options({ windowSize: 26 })
            .merge((d: any, c: any) => {
                d.ema26 = c;
            })
            .accessor((d: any) => d.ema26);

        const elder = elderRay();


        const macd26 = macd()
           .id(4)
           .options({
               fast: 12,
               signal: 9,
               slow: 26,
           })
           .merge((d: any, c: any) => {
               d.macd26 = c;
           })
           .accessor((d: any) => d.macd26);



       const  macdAppearance = {
               fillStyle: {
                   divergence: "#26a69a",
               },
               strokeStyle: {
                   macd: "#0093FF",
                   signal: "#D84315",
                   zero: "rgba(0, 0, 0, 0.5)",
               },
           };

        const calculatedData = macd26(elder(ema26(ema12(initialData))));

        const { margin, xScaleProvider } = this;

        const { data, xScale, xAccessor, displayXAccessor } = xScaleProvider(calculatedData);

        const max = xAccessor(data[data.length - 1]);
        const min = xAccessor(data[Math.max(0, data.length - 100)]);
        const xExtents = [min, max + 5];

        const gridHeight = height - margin.top - margin.bottom;

        const macdHeight = 110;
        const macdOrigin = (_: number, h: number) => [0, h - macdHeight];

        const barChartHeight = gridHeight / 4;
        const barChartOrigin = (_: number, h: number) => [0, h - barChartHeight - macdHeight];
        const chartHeight = gridHeight - macdHeight;

        const timeDisplayFormat = timeFormat(dateTimeFormat);

          
        if(symbol == '') {
            return <div></div>;
        }
        else if (data.length == 0) {
            return <div className="center">Loading...</div>;
        }
        else if (data.length > 0){
            return (
                
                <ChartCanvas
                    height={height}
                    ratio={ratio}
                    width={width}
                    margin={margin}
                    data={data}
                    displayXAccessor={displayXAccessor}
                    seriesName="Data"
                    xScale={xScale}
                    xAccessor={xAccessor}
                    xExtents={xExtents}
                    zoomAnchor={lastVisibleItemBasedZoomAnchor}
                >
                    <Chart id={2} height={barChartHeight} origin={barChartOrigin} yExtents={this.barChartExtents}>
                        <BarSeries fillStyle={this.volumeColor} yAccessor={this.volumeSeries} />
                    </Chart>
                    <Chart id={3} height={chartHeight} yExtents={this.candleChartExtents}>
                        <XAxis showTickLabel={false} />
                        <YAxis tickFormat={this.pricesDisplayFormat} />
                        <CandlestickSeries />
                        {/* <LineSeries yAccessor={ema26.accessor()} strokeStyle={ema26.stroke()} />
                        <CurrentCoordinate yAccessor={ema26.accessor()} fillStyle={ema26.stroke()} />
                        <LineSeries yAccessor={ema12.accessor()} strokeStyle={ema12.stroke()} />
                        <CurrentCoordinate yAccessor={ema12.accessor()} fillStyle={ema12.stroke()} /> */}
                        <MouseCoordinateX displayFormat={timeDisplayFormat} />
                        <MouseCoordinateY rectWidth={margin.right} displayFormat={this.pricesDisplayFormat} />
                        <EdgeIndicator
                            itemType="last"
                            rectWidth={margin.right}
                            fill={this.openCloseColor}
                            lineStroke={this.openCloseColor}
                            displayFormat={this.pricesDisplayFormat}
                            yAccessor={this.yEdgeIndicator}
                        />
                        {/* <MovingAverageTooltip
                            origin={[8, 24]}
                            options={[
                                {
                                    yAccessor: ema26.accessor(),
                                    type: "EMA",
                                    stroke: ema26.stroke(),
                                    windowSize: ema26.options().windowSize,
                                },
                                {
                                    yAccessor: ema12.accessor(),
                                    type: "EMA",
                                    stroke: ema12.stroke(),
                                    windowSize: ema12.options().windowSize,
                                },
                            ]}
                        /> */}

                        <ZoomButtons />
                        <OHLCTooltip origin={[8, 16]} />
                        <XAxis />
                    </Chart>
                    


                    
                    <Chart id={4} height={macdHeight} origin={macdOrigin} yExtents={macd26.accessor()} >
                        <XAxis gridLinesStrokeStyle="#e0e3eb" />
                        <YAxis ticks={3} tickFormat={this.pricesDisplayFormat}  />

                        <MACDSeries yAccessor={macd26.accessor()}   />

                        <MACDTooltip
                            origin={[8, 16]}
                            appearance={macdAppearance}
                            options={macd26.options()}
                            yAccessor={macd26.accessor()}
                        />

                    </Chart>


                    <CrossHairCursor />
                </ChartCanvas>
            );
        }
    }

    barChartExtents = (data: IOhlc) => {
        return data.volume;
    };

    candleChartExtents = (data: IOhlc) => {
        return [data.high, data.low];
    };

    yEdgeIndicator = (data: IOhlc) => {
        return data.close;
    };

    volumeColor = (data: IOhlc) => {
        return data.close > data.open ? "rgba(38, 166, 154, 0.5)" : "rgba(239, 83, 80, 0.5)";
    };

    volumeSeries = (data: IOhlc) => {
        return data.volume;
    };

    openCloseColor = (data: IOhlc) => {
        return data.close > data.open ? "#26a69a" : "#ef5350";
    };






}

//export default CandlestickChart;
//export default withSize({ style: { minHeight: 500 } })(withDeviceRatio()(CandlestickChart))
export default withSize({ style: { minHeight: 518 } })(withDeviceRatio()(CandlestickChart))


