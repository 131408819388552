const toNumber2Decimal = (number: any) => {
    return Number(number).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  const toNumber = (number: any) => {
    return Number(number).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  const commons = {
    toNumber2Decimal,
    toNumber,

  };
  export default commons;