//import * as React from 'react';
import React, { Fragment, Dispatch, useState, useEffect } from "react";
import {
  Table,
  Row,
  Col,

  Card,
  CardBody,

} from "reactstrap";

//service
import services from "../../services/services";
import IRiskReward from "src/models/RiskReward/IRiskReward";

//commons
import commons from '../../utilities/commons';

const RiskRewards = () => {
  const [riskReward,setRiskReward] = useState<IRiskReward>();
  const [symbol,setSymbol] = useState(''); // SET


  const getRiskReward = () => {
    services.getRiskReward(symbol).then((response: any) => {
      setRiskReward(response.data);  
    }).catch((e: Error) => {});
  };

  const getRiskRewardRatio = (symbol: string, risk: number, reward: number) => {
    services.getRiskRewardRatio(symbol, risk, reward).then((response: any) => {
      setRiskReward(response.data);  
    }).catch((e: Error) => {});
  };

  const onKeyPressSymbolHandler = ((e: React.KeyboardEvent<HTMLInputElement>): void => {
    let txtSymbol = e.currentTarget.value;
    let symbolValue = txtSymbol.toUpperCase();
    setSymbol(symbolValue);
    if( e.key == 'Enter' && txtSymbol != ''){
      getRiskReward();
      e.currentTarget.value = symbolValue;
    }
  });

  const onChangeSymbolHandler = ((e: React.ChangeEvent<HTMLInputElement>): void => {
    let txtSymbol = e.currentTarget.value;
    let symbolValue = txtSymbol.toUpperCase();
    setSymbol(symbolValue);
  });

  const onClickSearchHandler  = ((e: React.MouseEvent<HTMLButtonElement>): void => {
    if(symbol != ''){
      getRiskReward();

    }
  });

  const onChangeRiskHandler = ((e: React.ChangeEvent<HTMLInputElement>): void => {
    let risk = Number(e.currentTarget.value);
    let reward = Number(riskReward?.REWARD);
    getRiskRewardRatio(symbol, risk, reward);
    
  });

  const onChangeRewardHandler = ((e: React.ChangeEvent<HTMLInputElement>): void => {
    let reward = Number(e.currentTarget.value);
    let risk = Number(riskReward?.RISK);
    getRiskRewardRatio(symbol, risk, reward);
    
  });

    return (
      <React.Fragment>
        <Col xs={12}>
        <Row className="mb-3">
            <Col xs={2}></Col>
            <Col xs={2}>
              <input placeholder="Symbol" type="text" name="txtSymbol" value={symbol} className="form-control" onKeyPress={onKeyPressSymbolHandler} onChange={onChangeSymbolHandler} />
            </Col>
            <Col xs={1}>
              <button type="button" className="btn btn-primary" onClick={onClickSearchHandler}>Search</button>
            </Col>
            <Col xs={7}></Col>
          </Row>
          {riskReward && 
          <Row className="mb-3" >
            <Col xs={2}></Col>
            <Col xl={8}>
            <Card>

            <div className="card-header align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1 font-size-14">{riskReward?.SYMBOL}
                <i className="ms-3 me-1 dripicons-broadcast"></i>
                <i className="ms-3 me-2">Risk : </i>
                <span className={"mx-0 text-danger"} >{(commons.toNumber2Decimal(riskReward?.RISK))}</span>
                <i className="ms-3 me-2">Reward : </i>
                <span className={"mx-0 text-success"} >{(commons.toNumber2Decimal(riskReward?.REWARD))}</span>
                <i className="ms-3 me-2">Ratio : </i>
                <span className={"mx-0 text-primary"} >{(commons.toNumber2Decimal(riskReward?.RATIO))}</span>
                </h4>
              </div>

                <CardBody>

                <div className="table-responsive">
                  <Table className="table table-sm m-0 ">
                    <thead>
                      <tr>
                        <th className="text-end">Gap</th>
                        <th className="text-end">Price</th>
                        <th className="text-end mr-5">Percent Change</th>
                        <th className="text-center">Risk / Reward</th>
     
                      </tr>
                    </thead>
                    <tbody>
                      {(riskReward?.RISKS || []).map((item: any, key: number) => (
                        <tr key={key}  className={(
                          (item["GAP"] == 0) ? 'table-primary' 
                          : ((item["IS_REWARD"] > 0) ? 'table-success'
                          : ((item["IS_RISK"] > 0) ? 'table-danger'
                          :''))
                        )} >
                          <td className="text-end">{item["GAP"]}</td>
                          <td className="text-end">{(commons.toNumber2Decimal(item["PRICE"]))}</td>
                          <td className="text-end mr-5">{(commons.toNumber2Decimal(item["PERCENT_CHANGE"])) + " %"}</td>
                          <td className="text-center">
                            {item["PERCENT_CHANGE"] < 0 && 
                              <input className="form-check-input" type="radio" name="riskRadios" id="riskRadios" value={(commons.toNumber2Decimal(item["PRICE"]))} checked={riskReward?.RISKS[key]['IS_RISK']} onChange={onChangeRiskHandler} />
                            }                        
                            {item["PERCENT_CHANGE"] > 0 && 
                              <input className="form-check-input" type="radio" name="rewardRadios" id="rewardRadios" value={(commons.toNumber2Decimal(item["PRICE"]))} checked={riskReward?.RISKS[key]['IS_REWARD']} onChange={onChangeRewardHandler} />
                            }
                          </td>
                        </tr>
                      ))} 
                    </tbody>
                  </Table>
                </div>

            </CardBody>
              </Card>

            </Col>
            <Col xs={2}></Col>   
          </Row>
          }
        </Col>
      </React.Fragment>
      );
    

};




export default RiskRewards;

