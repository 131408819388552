import React from "react";
import MetaTags from "react-meta-tags";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Container, Row} from "reactstrap";

/** import Mini Widget data */
import MarketWidgets from "./MarketWidgets";

import QuoteTransactions from "./QuoteTransactions";
import QuoteTopPercentChanges from "./QuoteTopPercentChanges";
import QuoteTopVolumeChanges from "./QuoteTopVolumeChanges";
import QuoteTopValueChanges from "./QuoteTopValueChanges";


const Dashboard = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | Jabhoon</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Dashboard" breadcrumbItem="Dashboard" />

          <Row>
            <MarketWidgets />
          </Row>
          <Row>
            <QuoteTransactions />
          </Row>
          <Row>
            <QuoteTopPercentChanges />
            <QuoteTopVolumeChanges  />
            <QuoteTopValueChanges  />
          </Row>


        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
