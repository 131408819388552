//import * as React from 'react';
import React, { Fragment, Dispatch, useState, useEffect } from "react";
import {
  CardBody,
  Col,
  Row,
  Card,
} from "reactstrap";

//service
import services from "../../services/services";

import CandlestickChart from "./CandlestickChart/CandlestickChart";

import IOhlc  from "../../models/Ohlc/IOhlc";
import Ohlc from "../../models/Ohlc/Ohlc";

let height: number = 480;
let dateTimeFormat: string = "%d %b";
let width: number = 1120;
let ratio: number = 1;


const parseArrayData = (datas: IOhlc[]) => {
  let result: IOhlc[] = [];
  for (let [key, value] of Object.entries(datas)) {
    const hlco: Ohlc = new Ohlc(new Date(value['date']), value['close'], value['high'], value['low'], value['open'], value['volume']);
    result.push(hlco);
  }
  return result;
}


const StockChartSymbol = () => {

  const [symbol,setSymbol] = useState(''); // SET
  const [timing,setTiming] = useState('1d')
  const [data,setData] = useState<IOhlc[]>([]);
  
  React.useEffect(() => {
    //getStockChartSymbol(symbol, timing);
    // const interval = setInterval(() => {
    //   //alert("symbol " + symbol + " timing " + timing);
    //   if(symbol != '' && timing != ''){
    //     getStockChartSymbol(symbol, timing);
    //   }
    // }, 10000);
    // return () => clearInterval(interval);
    
  }, []);

  const getStockChartSymbol = (symbol: string, intervalTiming: string) => {
    setData([]);  
    dateTimeFormat = (intervalTiming=="1d")?"%d %b":"%d %b %H:%M";
    services.getStockChartSymbol(symbol, intervalTiming).then((response: any) => {  
      let chartData = parseArrayData(response.data);   
      setData(chartData);

    }).catch((e: Error) => {});

  }

  const onKeyPressSymbolHandler = ((e: React.KeyboardEvent<HTMLInputElement>): void => {
    let txtSymbol = e.currentTarget.value;
    let symbolValue = txtSymbol.toUpperCase();
    setSymbol(symbolValue);
    if( e.key == 'Enter' && txtSymbol != ''){
      if(symbolValue != '' && timing != ''){
        getStockChartSymbol(symbolValue, timing);
      }
      e.currentTarget.value = symbolValue;

    }
  });

  const onChangeSymbolHandler = ((e: React.ChangeEvent<HTMLInputElement>): void => {
    let txtSymbol = e.currentTarget.value;
    let symbolValue = txtSymbol.toUpperCase();
    setSymbol(symbolValue);
  });

  const onChangeIntervalHandler = ((e: React.ChangeEvent<HTMLSelectElement>): void => {
    let cboInterval = e.currentTarget.value;
    setTiming(cboInterval);
  });

  const onClickSearchHandler  = ((e: React.MouseEvent<HTMLButtonElement>): void => {
    if(symbol != '' && timing != ''){

      getStockChartSymbol(symbol, timing);
    }
  });


    return (
      <React.Fragment>
      <Col xl={6}>
          <Row>
            <Col xs={3}>
              <input placeholder="Symbol" type="text" name="txtSymbol" value={symbol} className="form-control" onKeyPress={onKeyPressSymbolHandler} onChange={onChangeSymbolHandler} />
            </Col>
            <Col xs={3}>
              <select name='cboTiming'className="form-control" onChange={onChangeIntervalHandler}
              value={timing}>
                  <option value="1m">1 Minute</option>
                  {/* <option value="2m">2 Minute</option>
                  <option value="5m">5 Minute</option>
                  <option value="15m">15 Minute</option>
                  <option value="30m">30 Minute</option>
                  <option value="60m">60 Minute</option>
                  <option value="90m">90 Minute</option>
                  <option value="1h">1 Hour</option>
                  <option value="1wk">1 Wk</option> */}
                  <option value="1d" >Daily</option>
                  {/* <option value="5d">Weekly</option>
                  <option value="1mo">Monthly</option>
                  <option value="3mo">Quarterly</option> */}
              </select>
            </Col>
            <Col xs={1}>
            <button type="button" className="btn btn-primary" onClick={onClickSearchHandler}>Search</button>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <CandlestickChart symbol = {symbol} data = {data}  dateTimeFormat={dateTimeFormat}  />
            </Col>
          </Row>
        </Col>
        </React.Fragment>
      );
    

};




export default StockChartSymbol;

