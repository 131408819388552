import React, { Fragment, Dispatch, useState, useEffect } from "react";
import MetaTags from "react-meta-tags";

// datatable related plugins
import BootstrapTable, { ColumnDescription } from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
  PaginationTotalStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
//import "./datatables.scss";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Container, Row, Col, Card, CardBody, CardTitle} from "reactstrap";

//service
import services from "../../services/services";
import INetProfitGrowth from '../../models/NetProfitGrowth/INetProfitGrowth';


const GrowthEveryYear = () => {

  const [netProfitGrowths,setNetProfitGrowths] = useState<INetProfitGrowth[]>([]);
  const [currentPage,setCurrentPage] = useState(1);
  const [perPage,setPerPage] = useState(20);

  React.useEffect(() => {
    getNetProfitGrowths();
  }, []);

  const getNetProfitGrowths = () => {
    services.getNetProfitGrowthYears("all","PE","ASC").then((response: any) => {
      setNetProfitGrowths(response.data);  
    }).catch((e: Error) => {});
  };

  const sortFunc = (a: any, b: any, order: any, dataField: any) => {
    if (order === 'asc') {
      return Number(b) - Number(a);
    }
    return Number(a) - Number(b);
  };

  const columns = [
    {
      text: "#",
      dataField: "ID",
      type: 'number',
      formatter: (cell: any, row: any, rowIdx: any) => {
        let rowNumber = (currentPage * perPage) - perPage  + rowIdx + 1;
        return <span>{rowNumber}</span>;
      },
      align: 'right',
      headerAlign: 'right',
      headerClasses: 'bg-soft-light border-primary'
    },
    {
      text: "Symbol",
      dataField: "SYMBOL",
      type: 'string',
      sort: true,
      headerClasses: 'bg-soft-light border-primary'
    },
    {
      text: "Y2023",
      dataField: "NET_PROFIT_4",
      type: 'number',
      sort: true,
      sortFunc,
      align: 'right',
      headerAlign: 'right',
      headerClasses: 'bg-soft-light border-primary'
    },
    {
      text: "Y2022",
      dataField: "NET_PROFIT_3",
      type: 'number',
      sort: true,
      sortFunc,
      align: 'right',
      headerAlign: 'right',
      headerClasses: 'bg-soft-light border-primary'
    },
    {
      text: "Y2021",
      dataField: "NET_PROFIT_2",
      type: 'number',
      sort: true,
      sortFunc,
      align: 'right',
      headerAlign: 'right',
      headerClasses: 'bg-soft-light border-primary'
    },
    {
      text: "Y2020",
      dataField: "NET_PROFIT_1",
      type: 'number',
      sort: true,
      sortFunc,
      align: 'right',
      headerAlign: 'right',
      headerClasses: 'bg-soft-light border-primary'
    },
    {
      text: "PE",
      dataField: "PE",
      type: 'number',
      sort: true,
      sortFunc,
      align: 'right',
      headerAlign: 'right',
      headerClasses: 'bg-soft-light border-primary'
    },
    {
      text: "EPS",
      dataField: "EPS",
      type: 'number',
      sort: true,
      sortFunc,
      align: 'right',
      headerAlign: 'right',
      headerClasses: 'bg-soft-light border-primary'
    },
    {
      text: "PBV",
      dataField: "PBV",
      type: 'number',
      sort: true,
      sortFunc,
      align: 'right',
      headerAlign: 'right',
      headerClasses: 'bg-soft-light border-primary'
    },
    {
      text: "Book Value",
      dataField: "BOOK_VALUE",
      type: 'number',
      sort: true,
      sortFunc,
      align: 'right',
      headerAlign: 'right',
      headerClasses: 'bg-soft-light border-primary'
    },
    {
      text: "Dvd Yield",
      dataField: "DVD_YIELD",
      type: 'number',
      sort: true,
      sortFunc,
      align: 'right',
      headerAlign: 'right',
      headerClasses: 'bg-soft-light border-primary'
    }
  ];

  const customTotal = (from: any, to: any, size: any) => (
    <span className="react-bootstrap-table-pagination-total ms-3">
      { from } - { to } of { size }
    </span>
  );
  const rowClass = (row: any, rowIdx: any) => {
    return rowIdx % 2 === 0 ? '' : 'bg-light';
  }

  /*const pageButtonRenderer: any = (
    page: any,
    active: any,
    disable: any,
    title: any,
    onPageChange: any
  ) => {
    const handleClick = (e: any) => {
      e.preventDefault();
      //onPageChange(page);
    };
    return (
      <button type="button" className="me-1 btn btn-sm btn-primary">
        <span className="" >{currentPage}</span>
      </button>
    );
  };*/

  const pageOptions: any = {
    sizePerPageList:[10,20,50,100, {
      text: 'All', value: netProfitGrowths.length
    }],
    paginationSize: 3,
    withFirstAndLast: false,
    hideSizePerPage: true,
    lastPageText: '>>',
    firstPageText: '<<',
    nextPageText: '>',
    prePageText: '<',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    alwaysShowAllBtns: true,

    sizePerPage: perPage,
    totalSize: netProfitGrowths.length,
    custom: true,
   
    onPageChange: (page: any, sizePerPage: any) => {
      setCurrentPage(page);
      setPerPage(sizePerPage);
    },
    /*onSizePerPageChange: (page: any, sizePerPage: any) => {
      setCurrentPage(page);
      setPerPage(sizePerPage);
    },*/
    //pageButtonRenderer

  };


  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Growth Every Year | Jabhoon</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Fundamental" breadcrumbItem="Growth Every Year" />

          <Row>
            <Col xl={12} className="table-responsive">

              <PaginationProvider 
                pagination={paginationFactory(pageOptions)}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider 
                    keyField="SYMBOL"
                    columns={columns}
                    data={netProfitGrowths}
                    search
                  >
                    {toolkitProps => (
                      <React.Fragment>

                      <Row>
                        <Col xl="12">
                          <BootstrapTable
                            rowClasses={rowClass}                              
                            bordered={false}
                            hover={true}
                            defaultSortDirection="asc"
                            classes={"table table-md align-middle table-nowrap"}
                            headerWrapperClasses={"thead-light"}
                            {...toolkitProps.baseProps}
                            {...paginationTableProps}
                          />          
                        </Col>
                      </Row>

                      <Row className="align-items-md-center mt-30">
                        <Col className="inner-custom-pagination d-flex">
                          <div className="d-inline">
                            {/* <SizePerPageDropdownStandalone
                              {...paginationProps}
                            /> */}
                            <PaginationTotalStandalone
                              { ...paginationProps }
                            />
                          </div>
                          <div className="text-md-right ms-auto">
                            <PaginationListStandalone
                              {...paginationProps}
                            />
                          </div>
                        </Col>
                      </Row>


                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
    
            </Col>
          </Row>


        </Container>
      </div>
    </React.Fragment>
  );
};

export default GrowthEveryYear;
