interface DashboardProps {
    id : number;
    title : string;
    price : number;
    percent : number;
    volume : number;
    high : number;
    low : number;
    marketTime : Date;
    statusColor : string;
    series : Array<any>;
}

const WidgetsData : Array<DashboardProps> = [
    {
        id: 1,
        title: "SET",
        price: 1780.22,
        percent: 0.32,
        volume: 4523,
        high: 1780.22,
        low: 1780.22,
        marketTime: new Date(),
        statusColor: "success",
        series: [2, 10, 18, 22, 36, 15, 47, 75, 65, 19, 14, 2, 47, 42, 15, 10, 18, 22, 36, 15, 47, 75, 65, 19, 14, 2, 47, 42, 15],
    },
    {
        id: 2,
        title: "SET 100",
        price: 780.82,
        percent: 0.32,
        volume: 4523,
        high: 780.82,
        low: 780.82,
        marketTime: new Date(),
        statusColor: "danger",
        series: [15, 42, 47, 2, 14, 19, 65, 75, 47, 15, 42, 47, 2, 14, 12, 10, 18, 22, 36, 15, 47, 75, 65, 19, 14, 2, 47, 42, 15]
    },
    {
        id: 3,
        title: "SET 50",
        price: 1062.36,
        percent: 0.32,
        volume: 4523,
        high: 1062.36,
        low: 1062.36,
        marketTime: new Date(),
        statusColor: "success",
        series: [47, 15, 2, 67, 22, 20, 36, 60, 60, 30, 50, 11, 12, 3, 8, 10, 18, 22, 36, 15, 47, 75, 65, 19, 14, 2, 47, 42, 15]
    },
    {
        id: 5,
        title: "MAI",
        price: 976.01,
        percent: 0.32,
        volume: 4523,
        high: 976.01,
        low: 976.01,
        marketTime: new Date(),
        statusColor: "success",
        series: [12, 14, 2, 47, 42, 15, 47, 75, 65, 19, 14, 2, 47, 42, 15, 10, 18, 22, 36, 15, 47, 75, 65, 19, 14, 2, 47, 42, 15]
    },
];

export { WidgetsData };