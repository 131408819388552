//import * as React from 'react';
import React, { Fragment, Dispatch, useState, useEffect } from "react";
import {
  Col,
  Row,
} from "reactstrap";

//service
import services from "../../services/services";

import QuoteHistoricalSymbol from "./QuoteHistoricalSymbol/QuoteHistoricalSymbol";
import IWatch from "src/models/Watch/IWatch";

const QuoteHistoricals = () => {
  const [watches,setWatches] = useState<IWatch[]>([]);
  const [symbol,setSymbol] = useState(''); // SET

  const getQuotes = () => {
    services.getQuotesHistoricalSymbol(symbol).then((response: any) => {
      setWatches(response.data);  
    }).catch((e: Error) => {});
  };

  const onKeyPressSymbolHandler = ((e: React.KeyboardEvent<HTMLInputElement>): void => {
    let txtSymbol = e.currentTarget.value;
    let symbolValue = txtSymbol.toUpperCase();
    setSymbol(symbolValue);
    if( e.key == 'Enter' && txtSymbol != ''){
      getQuotes();
      e.currentTarget.value = symbolValue;
    }
  });

  const onChangeSymbolHandler = ((e: React.ChangeEvent<HTMLInputElement>): void => {
    let txtSymbol = e.currentTarget.value;
    let symbolValue = txtSymbol.toUpperCase();
    setSymbol(symbolValue);
  });

  const onClickSearchHandler  = ((e: React.MouseEvent<HTMLButtonElement>): void => {
    if(symbol != ''){
      getQuotes();
    }
  });

    return (
      <React.Fragment>
        <Col xl={12}>
          <Row className="mb-3">
            <Col xs={2}></Col>
            <Col xs={2}>
              <input placeholder="Symbol" type="text" name="txtSymbol" value={symbol} className="form-control" onKeyPress={onKeyPressSymbolHandler} onChange={onChangeSymbolHandler} />
            </Col>
            <Col xs={1}>
              <button type="button" className="btn btn-primary" onClick={onClickSearchHandler}>Search</button>
            </Col>
            <Col xs={7}></Col>
          </Row>
          <Row>
            <Col xs={2}></Col>
            <Col xs={8}>
            {(watches || []).map((item: any, key: number) => (
              <QuoteHistoricalSymbol key={key} symbol = {item["SYMBOL"]} quotes = {item["QUOTES"]} quote = {item["QUOTE"]}  />
            ))}   
            </Col>
            <Col xs={2}></Col>   
          </Row>
        </Col>
        </React.Fragment>
      );
    

};




export default QuoteHistoricals;

