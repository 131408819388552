//import * as React from 'react';
import React, { Fragment, Dispatch, useState, useEffect } from "react";
import {
  CardBody,
  Col,
  Row,
  Card,
} from "reactstrap";

//service
import services from "../../services/services";

import CandlestickChart from "./CandlestickGapChart/CandlestickGapChart";

import IOhlc  from "../../models/OhlcOption/IOhlcOption";
import Ohlc from "../../models/OhlcOption/OhlcOption";

let height: number = 480;
let dateTimeFormat: string = "%d %b";
let width: number = 1120;
let ratio: number = 1;


const parseArrayData = (datas: IOhlc[]) => {
  let result: IOhlc[] = [];
  for (let [key, value] of Object.entries(datas)) {
    const hlco: Ohlc = new Ohlc(new Date(value['date']), value['close'], value['high'], value['low'], value['open'], value['volume'], value['goi'], value['goi2'], value['goi3']);
    result.push(hlco);
  }
  return result;
}


const StockGapChartSymbol = () => {

  const [symbol,setSymbol] = useState(''); //SET
  const [timing,setTiming] = useState('1d')
  const [data,setData] = useState<IOhlc[]>([]);
  
  React.useEffect(() => {
    //getStockChartSymbol(symbol, timing);
  }, []);

  const getStockChartSymbol = (symbol: string, intervalTiming: string) => {
    setData([]);  
    dateTimeFormat = (intervalTiming=="1d")?"%d %b":"%d %b %H:%M";
    services.getStockChartSymbol(symbol, intervalTiming).then((response: any) => {  
      let chartData = parseArrayData(response.data);  

      setData(chartData);

    }).catch((e: Error) => {});

  }

  const onKeyPressSymbolHandler = ((e: React.KeyboardEvent<HTMLInputElement>): void => {
    let txtSymbol = e.currentTarget.value;
    let symbolValue = txtSymbol.toUpperCase();
    setSymbol(symbolValue);
    if( e.key == 'Enter' && txtSymbol != ''){
      if(symbolValue != '' && timing != ''){
        getStockChartSymbol(symbolValue, timing);
      }
      e.currentTarget.value = symbolValue;

    }
  });

  const onChangeSymbolHandler = ((e: React.ChangeEvent<HTMLInputElement>): void => {
    let txtSymbol = e.currentTarget.value;
    let symbolValue = txtSymbol.toUpperCase();
    setSymbol(symbolValue);
  });

  const onChangeIntervalHandler = ((e: React.ChangeEvent<HTMLSelectElement>): void => {
    let cboInterval = e.currentTarget.value;
    setTiming(cboInterval);
  });

  const onClickSearchHandler  = ((e: React.MouseEvent<HTMLButtonElement>): void => {
    if(symbol != '' && timing != ''){

      getStockChartSymbol(symbol, timing);
    }
  });


    return (
      <React.Fragment>
      <Col xl={12} xm={12} xs={12}>
          <Row>
            <Col xl={2} xm={2} xs={3}>
              <input placeholder="Symbol" type="text" name="txtSymbol" value={symbol} className="form-control" onKeyPress={onKeyPressSymbolHandler} onChange={onChangeSymbolHandler} />
            </Col>
            <Col xl={1} xm={2} xs={3}>
              <select name='cboTiming'className="form-control" onChange={onChangeIntervalHandler}
              value={timing}>
                  <option value="1d" >Daily</option>
              </select>
            </Col>
            <Col xl={1} xm={1} xs={1}>
            <button type="button" className="btn btn-primary" onClick={onClickSearchHandler}>Search</button>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <CandlestickChart symbol = {symbol} data = {data}  dateTimeFormat={dateTimeFormat}  />
            </Col>
          </Row>
        </Col>
        </React.Fragment>
      );
    

};




export default StockGapChartSymbol;

