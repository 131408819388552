import React, { useState } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CardBody,
  Col,
  Card,
} from "reactstrap";
import classnames from "classnames";
import moment from 'moment';


//SimpleBar
import SimpleBar from "simplebar-react";

//import Charts
import ReactApexChart from "react-apexcharts";

//service
import services from "../../services/services";
import IPatternScore from '../../models/PatternScore/IPatternScore';



const PatternScores = () => {

  const [topPatternScores,setTopPatternScores] = useState<IPatternScore[]>([]);


  React.useEffect(() => {
    getTopPatternScores();

  }, []);

  const getTopPatternScores = () => {
    services.getTopDailyPatternScores().then((response: any) => {
      setTopPatternScores(response.data);  
    }).catch((e: Error) => {});
  };




  const options: Object = {
    chart: {
      type: "candlestick",
      toolbar: { show: false },
      // sparkline: {
      //   enabled: true
      // },    
      zoom: {
        enabled: false,
        autoScaleYaxis: true,
      }
    },
    tooltip: {
      enabled: false,
    },
    xaxis: {
      type: 'datetime',
      tooltip: {
        enabled: true,
      }
    },
    yaxis: {
      tooltip: {
        enabled: true,
        offsetX: 0,
      },
      opposite: true,
    },
    
    plotOptions: {
        candlestick: {
            colors: {
                upward: '#26A69A',
                downward: '#EF5350'
            }
        },
    },
    colors: ['#26A69A', '#EF5350']
  
  };


  return (
    <React.Fragment>
      <Col xl={12}>
        {/* <Card> */}

          {/* <CardBody className="px-0">
            <TabContent activeTab={activeTab}> */}
                <SimpleBar
                  className="table-responsive px-3"
                  style={{ maxHeight: "600px" }}
                >
                  <table className="table align-middle table-nowrap table-borderless">
                    <tbody>
                    {(topPatternScores || []).map((item: any, key: number) => (
                      <tr key={key}>
                        <td>
                          <div>
                            <h5 className="font-size-12 mb-1">{item["SYMBOL"]}</h5>
                            <p className="text-muted mb-0 font-size-10">{moment(item["MARKET_DATE"]).format('ddd DD HH:mm')}</p>
                          </div>
                        </td>

                        <td>
                          <div className="text-end">
                            <h5 className="font-size-12 mb-0">{item["PATTERN_NAME"]}</h5>
                            <p className="text-muted mb-0 font-size-10">Pattern</p>
                          </div>
                        </td>

                        <td>
                          <div className="text-end">
                            <h5 className="font-size-12 mb-0">{item["SCORE_WIN"]}</h5>
                            <p className="text-muted mb-0 font-size-10">Score</p>
                          </div>
                        </td> 

                        <td>
                          <div className="text-end">
                            <h5 className="font-size-12 mb-0">{item["PLAYS"]}</h5>
                            <p className="text-muted mb-0 font-size-10">Play</p>
                          </div>
                        </td> 

                        <td>
                          <div className="text-end">
                            <h5 className="font-size-12 mb-0">{item["AVERAGE_PERCENT_WIN"]}</h5>
                            <p className="text-muted mb-0 font-size-10">Average Win</p>
                          </div>
                        </td>  

                        <td>
                          <div className="text-end">
                            <h5 className="font-size-12 mb-0">{item["AVERAGE_PERCENT_LOSE"]}</h5>
                            <p className="text-muted mb-0 font-size-10">Average Lose</p>
                          </div>
                        </td>  

                        <td>
                          <div className="text-end">                        
                            <ReactApexChart
                              width= "400px"
                              height= "200px"
                              options={options}
                              series={[{ data: [...item["SERIES"]] }]}
                              type="candlestick"
                              className="apex-charts"
                              //dir="ltr"
                            />
                          </div>
                        </td>
                        
                      </tr>
                    ))}       
                    </tbody>
                  </table>
                </SimpleBar>


            {/* </TabContent>
          </CardBody> */}
        {/* </Card> */}
      </Col>
    </React.Fragment>
  );
};

export default PatternScores;
