import axios from "axios";

export default axios.create({

  baseURL: "https://www.jabhoon.com/service/api/",
  //baseURL: "http://localhost:90/jabhoon/service/api/",

  headers: {
    "Content-type": "application/json",
  }
  

});