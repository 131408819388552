import React from "react";
import MetaTags from "react-meta-tags";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Container, Row} from "reactstrap";

/** import Mini Widget data */
import RiskRewards from "./RiskRewards";


const RiskReward = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Risk Reward | Jabhoon</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Trading" breadcrumbItem="Risk Reward" />

          <Row>
            <RiskRewards />
          </Row>



        </Container>
      </div>
    </React.Fragment>
  );
};

export default RiskReward;
