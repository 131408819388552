import React from "react";
import MetaTags from "react-meta-tags";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Container, Row} from "reactstrap";

/** import Mini Widget data */
import MarketMiniWidgets from "./MarketMiniWidgets";
import QuoteTransactions from "./QuoteTransactions";
import StockChartSymbol from "./StockChartSymbol";


const Monitor = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Monitor | Jabhoon</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Trading" breadcrumbItem="Monitor" />

          <Row>
            <MarketMiniWidgets />
          </Row>
          <Row>
            <QuoteTransactions />
            <StockChartSymbol />
          </Row>



        </Container>
      </div>
    </React.Fragment>
  );
};

export default Monitor;
