import React, { useState } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CardBody,
  Col,
  Card,
} from "reactstrap";
import classnames from "classnames";
import moment from 'moment';

//SimpleBar
import SimpleBar from "simplebar-react";

//service
import services from "../../services/services";
import IQuote from '../../models/Quote/IQuote';

const QuoteTopVolumeChanges = () => {
  const [activeTab, setActiveTab] = useState<string>("1");

  const [topGainerQuotes,setTopGainerQuotes] = useState<IQuote[]>([]);
  const [topLoserQuotes,setTopLoserQuotes] = useState<IQuote[]>([]);
  const [topAllQuotes,setTopAllQuotes] = useState<IQuote[]>([]);

  React.useEffect(() => {
    getTopGainerQuotesInit();
    getTopLoserQuotesInit();
    getTopAllQuotesInit();
    const interval = setInterval(() => {
      getTopGainerQuotes();
      getTopLoserQuotes();
      getTopAllQuotes();
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  const toggle = (tab: any) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const getTopGainerQuotesInit = () => {
    services.getTopGainerVolumeChangeQuotesInit().then((response: any) => {
      setTopGainerQuotes(response.data);  
    }).catch((e: Error) => {});
  };
  const getTopGainerQuotes = () => {
    services.getTopGainerVolumeChangeQuotes().then((response: any) => {
      setTopGainerQuotes(response.data);  
    }).catch((e: Error) => {});
  };

  const getTopLoserQuotesInit = () => {
    services.getTopLoserVolumeChangeQuotesInit().then((response: any) => {
      setTopLoserQuotes(response.data);  
    }).catch((e: Error) => {});
  };
  const getTopLoserQuotes = () => {
    services.getTopLoserVolumeChangeQuotes().then((response: any) => {
      setTopLoserQuotes(response.data);  
    }).catch((e: Error) => {});
  };

  const getTopAllQuotesInit = () => {
    services.getTopAllVolumeChangeQuotesInit().then((response: any) => {
      setTopAllQuotes(response.data);  
    }).catch((e: Error) => {});
  };
  const getTopAllQuotes = () => {
    services.getTopAllVolumeChangeQuotes().then((response: any) => {
      setTopAllQuotes(response.data);  
    }).catch((e: Error) => {});
  };



  return (
    <React.Fragment>
      <Col xl={4}>
        <Card>
          <div className="card-header align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1 font-size-14">Top Volume Change</h4>
            <div className="flex-shrink-0">
              <Nav
                className="justify-content-end nav-tabs-custom rounded card-header-tabs"
                role="tablist"
              >
                <NavItem>
                  <NavLink
                    to="#"
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Gainer
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    to="#"
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    Loser
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    to="#"
                    className={classnames({ active: activeTab === "3" })}
                    onClick={() => {
                      toggle("3");
                    }}
                  >
                    All
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </div>

          <CardBody className="px-0">
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <SimpleBar
                  className="table-responsive px-3"
                  style={{ maxHeight: "480px" }}
                >
                  <table className="table align-middle table-nowrap table-borderless">
                    <tbody>
                    {(topGainerQuotes || []).map((item: any, key: number) => (
                      <tr key={key}>
                        <td>
                          <div className={"font-size-20 " + ((item["REGULAR_MARKET_CHANGE_PERCENT"] >= 0) ? 'text-success' : 'text-danger')}>
                            <i className={"bx " + ((item["REGULAR_MARKET_CHANGE_PERCENT"] >= 0) ? 'bx-up-arrow-circle' : 'bx-down-arrow-circle') + " d-block"}></i>
                          </div>
                        </td>

                        <td>
                          <div>
                            <h5 className="font-size-12 mb-1">{item["SYMBOL"]}</h5>
                            <p className="text-muted mb-0 font-size-10">{moment(item["REGULAR_MARKET_TIME"]).format('ddd DD HH:mm')}</p>
                          </div>
                        </td>

                        <td>
                          <div className="text-end">
                            <h5 className="font-size-12 mb-0">{item["REGULAR_MARKET_PRICE"]}</h5>
                            <p className="text-muted mb-0 font-size-10">Price</p>
                          </div>
                        </td>                

                        <td>
                          <div className="text-end">
                            <h5 className={"font-size-12 mb-0 " + ((item["REGULAR_MARKET_CHANGE_PERCENT"] >= 0) ? 'text-success' : 'text-danger')}>{Number(item["REGULAR_MARKET_CHANGE_PERCENT"]).toFixed(2)} %</h5>
                            <p className="text-muted mb-0 font-size-10">Change</p>
                          </div>
                        </td>

                        <td>
                          <div className="text-end">
                            <h5 className="font-size-12 text-muted mb-0">{(item["REGULAR_MARKET_VOLUME"]/1000000.00).toFixed(2)  + " M"}</h5>
                            <p className="text-muted mb-0 font-size-10">Volume</p>
                          </div>
                        </td>
                        
                      </tr>
                    ))}       
                    </tbody>
                  </table>
                </SimpleBar>
              </TabPane>

              <TabPane tabId="2">
                <SimpleBar
                  className="table-responsive px-3"
                  style={{ maxHeight: "480px" }}
                >
                  <table className="table align-middle table-nowrap table-borderless">
                    <tbody>
                    {(topLoserQuotes || []).map((item: any, key: number) => (
                      <tr key={key}>
                        <td>
                          <div className={"font-size-20 " + ((item["REGULAR_MARKET_CHANGE_PERCENT"] >= 0) ? 'text-success' : 'text-danger')}>
                            <i className={"bx " + ((item["REGULAR_MARKET_CHANGE_PERCENT"] >= 0) ? 'bx-up-arrow-circle' : 'bx-down-arrow-circle') + " d-block"}></i>
                          </div>
                        </td>

                        <td>
                          <div>
                            <h5 className="font-size-12 mb-1">{item["SYMBOL"]}</h5>
                            <p className="text-muted mb-0 font-size-10">{moment(item["REGULAR_MARKET_TIME"]).format('ddd DD HH:mm')}</p>
                          </div>
                        </td>

                        <td>
                          <div className="text-end">
                            <h5 className="font-size-12 mb-0">{item["REGULAR_MARKET_PRICE"]}</h5>
                            <p className="text-muted mb-0 font-size-10">Price</p>
                          </div>
                        </td>                

                        <td>
                          <div className="text-end">
                            <h5 className={"font-size-12 mb-0 " + ((item["REGULAR_MARKET_CHANGE_PERCENT"] >= 0) ? 'text-success' : 'text-danger')}>{Number(item["REGULAR_MARKET_CHANGE_PERCENT"]).toFixed(2)} %</h5>
                            <p className="text-muted mb-0 font-size-10">Change</p>
                          </div>
                        </td>

                        <td>
                          <div className="text-end">
                            <h5 className="font-size-12 text-muted mb-0">{(item["REGULAR_MARKET_VOLUME"]/1000000.00).toFixed(2)  + " M"}</h5>
                            <p className="text-muted mb-0 font-size-10">Volume</p>
                          </div>
                        </td>
                        
                      </tr>
                    ))}       
                    </tbody>
                  </table>
                </SimpleBar>
              </TabPane>

              <TabPane tabId="3">
                <SimpleBar
                  className="table-responsive px-3"
                  style={{ maxHeight: "480px" }}
                >
                  <table className="table align-middle table-nowrap table-borderless">
                    <tbody>
                    {(topAllQuotes || []).map((item: any, key: number) => (
                      <tr key={key}>
                        <td>
                          <div className={"font-size-20 " + ((item["REGULAR_MARKET_CHANGE_PERCENT"] >= 0) ? 'text-success' : 'text-danger')}>
                            <i className={"bx " + ((item["REGULAR_MARKET_CHANGE_PERCENT"] >= 0) ? 'bx-up-arrow-circle' : 'bx-down-arrow-circle') + " d-block"}></i>
                          </div>
                        </td>

                        <td>
                          <div>
                            <h5 className="font-size-12 mb-1">{item["SYMBOL"]}</h5>
                            <p className="text-muted mb-0 font-size-10">{moment(item["REGULAR_MARKET_TIME"]).format('ddd DD HH:mm')}</p>
                          </div>
                        </td>

                        <td>
                          <div className="text-end">
                            <h5 className="font-size-12 mb-0">{item["REGULAR_MARKET_PRICE"]}</h5>
                            <p className="text-muted mb-0 font-size-10">Price</p>
                          </div>
                        </td>                

                        <td>
                          <div className="text-end">
                            <h5 className={"font-size-12 mb-0 " + ((item["REGULAR_MARKET_CHANGE_PERCENT"] >= 0) ? 'text-success' : 'text-danger')}>{Number(item["REGULAR_MARKET_CHANGE_PERCENT"]).toFixed(2)} %</h5>
                            <p className="text-muted mb-0 font-size-10">Change</p>
                          </div>
                        </td>

                        <td>
                          <div className="text-end">
                            <h5 className="font-size-12 text-muted mb-0">{(item["REGULAR_MARKET_VOLUME"]/1000000.00).toFixed(2)  + " M"}</h5>
                            <p className="text-muted mb-0 font-size-10">Volume</p>
                          </div>
                        </td>
                        
                      </tr>
                    ))}       
                    </tbody>
                  </table>
                </SimpleBar>
              </TabPane>

            </TabContent>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default QuoteTopVolumeChanges;
