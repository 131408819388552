//import * as React from 'react';
import React, { Fragment, Dispatch, useState, useEffect } from "react";
import {
  Col,
  Row,
} from "reactstrap";

//service
import services from "../../services/services";
import IWatch from "src/models/Watch/IWatch";

import WatchSymbol from "./WatchSymbol/WatchSymbol";

const WatchTransactions = () => {
  const [watches,setWatches] = useState<IWatch[]>([]);
  
  React.useEffect(() => {

    getQuotes();
    const interval = setInterval(() => {
      getQuotes();
    }, 30000);
    return () => clearInterval(interval);

  }, []);


  const getQuotes = () => {
    services.getTickerPortfolioTradings ().then((response: any) => {
      setWatches(response.data);  
    }).catch((e: Error) => {});
  };

    return (
      <React.Fragment>
        <Col xl={12}>
          <Row>
            {(watches || []).map((item: any, key: number) => (
              <WatchSymbol key={key} symbol = {item["SYMBOL"]} quotes = {item["QUOTES"]} quote = {item["QUOTE"]}  />
            ))}   
          </Row>
        </Col>
        </React.Fragment>
      );
    

};




export default WatchTransactions;

