import { format } from "d3-format";
import { timeFormat } from "d3-time-format";
import * as React from "react";

import {
    elderRay,
    discontinuousTimeScaleProviderBuilder,
    Chart,
    compare,
    LineSeries,
    StraightLine,
    ChartCanvas,
    BarSeries,
    CandlestickSeries,
    OHLCTooltip,
    SingleValueTooltip,
    lastVisibleItemBasedZoomAnchor,
    XAxis,
    YAxis,
    CrossHairCursor,
    EdgeIndicator,
    MouseCoordinateX,
    MouseCoordinateY,
    ZoomButtons,
    withDeviceRatio,
    withSize,
    macd,
    MACDSeries,
} from "react-financial-charts";

import { MACDTooltip } from "@react-financial-charts/tooltip";
import IOhlc from "../../../models/OhlcOption/IOhlcOption";

interface CandlestickChartProps {
    data: IOhlc[];
    height: number;
    dateTimeFormat?: string;
    width: number;
    ratio: number;
    symbol: string;
}

class CandlestickGapChart extends React.Component<CandlestickChartProps> {

    margin = { left: 0, right: 44, top: 12, bottom: 24 };
    pricesDisplayFormat = format(".2f");
    xScaleProvider = discontinuousTimeScaleProviderBuilder().inputDateAccessor(
        (d: IOhlc) => d.date,
    );
    constructor(props: CandlestickChartProps) {
        super(props);
        this.state = {
            
        };
      }



    render() {
        const { data: initialData, dateTimeFormat = "%d %b", height, ratio, width, symbol} = this.props;


 



        const macd26 = macd()
           .id(4)
           .options({
               fast: 12,
               signal: 9,
               slow: 26,
           })
           .merge((d: any, c: any) => {
               d.macd26 = c;
           })
           .accessor((d: any) => d.macd26);
        const  macdAppearance = {
            fillStyle: {
                divergence: "#26a69a",
            },
            strokeStyle: {
                macd: "#0093FF",
                signal: "#D84315",
                zero: "rgba(0, 0, 0, 0.5)",
            },
        };


        //const calculatedData = initialData;
        const calculatedData = macd26(initialData);


        const { margin, xScaleProvider } = this;

        const { data, xScale, xAccessor, displayXAccessor } = xScaleProvider(calculatedData);

        const max = xAccessor(data[data.length - 1]);
        const min = xAccessor(data[Math.max(0, data.length - 100)]);
        const xExtents = [min, max + 5];

        const gridHeight = height - margin.top - margin.bottom;
        const goiHeight = 120;
        const goiOrigin = (_: number, h: number) => [0, h - goiHeight];

        const macdHeight = 90;
        const macdOrigin = (_: number, h: number) => [0, h - goiHeight - macdHeight];


        const barChartHeight = gridHeight / 4;
        const barChartOrigin = (_: number, h: number) => [0, h - barChartHeight - goiHeight - macdHeight];
        const chartHeight = gridHeight - goiHeight - macdHeight;

        const timeDisplayFormat = timeFormat(dateTimeFormat);

        const levelFormat = format(".0f");

          
        if(symbol == '') {
            return <div></div>;
        }
        else if (data.length == 0) {
            return <div className="center">Loading...</div>;
        }
        else if (data.length > 0){
            return (
                
                <ChartCanvas
                    height={height}
                    ratio={ratio}
                    width={width}
                    margin={margin}
                    data={data}
                    displayXAccessor={displayXAccessor}
                    seriesName="Data"
                    xScale={xScale}
                    xAccessor={xAccessor}
                    xExtents={xExtents}
                    zoomAnchor={lastVisibleItemBasedZoomAnchor}
                >
                    <Chart id={2} height={barChartHeight} origin={barChartOrigin} yExtents={this.barChartExtents}>
                        <BarSeries fillStyle={this.volumeColor} yAccessor={this.volumeSeries} />
                    </Chart>
                    <Chart id={3} height={chartHeight} yExtents={this.candleChartExtents}>
                        <XAxis showTickLabel={false} />
                        <YAxis tickFormat={this.pricesDisplayFormat} />
                        <CandlestickSeries />

                        <MouseCoordinateX displayFormat={timeDisplayFormat} />
                        <MouseCoordinateY rectWidth={margin.right} displayFormat={this.pricesDisplayFormat} />
                        <EdgeIndicator
                            itemType="last"
                            rectWidth={margin.right}
                            fill={this.openCloseColor}
                            lineStroke={this.openCloseColor}
                            displayFormat={this.pricesDisplayFormat}
                            yAccessor={this.yEdgeIndicator}
                        />


                        <ZoomButtons />
                        <OHLCTooltip origin={[8, 16]} />
                        <XAxis />
                    </Chart>
                    

                    <Chart id={4} height={macdHeight} origin={macdOrigin} yExtents={macd26.accessor()} >
                        <XAxis gridLinesStrokeStyle="#e0e3eb" />
                        <YAxis ticks={3} tickFormat={this.pricesDisplayFormat}  />
                        <MouseCoordinateY rectWidth={this.margin.right} displayFormat={this.pricesDisplayFormat} />
                        <MACDSeries yAccessor={macd26.accessor()}   />

                        <MACDTooltip
                            origin={[8, 16]}
                            appearance={macdAppearance}
                            options={macd26.options()}
                            yAccessor={macd26.accessor()}
                        />

                    </Chart>

                    
                    <Chart id={5} height={goiHeight} origin={goiOrigin} yExtents={this.goiChartExtents} >
                        <XAxis gridLinesStrokeStyle="#e0e3eb" />
                        <YAxis ticks={3} tickFormat={levelFormat}  />
                        <MouseCoordinateY rectWidth={this.margin.right} displayFormat={levelFormat} />

                        <StraightLine yValue={20} lineDash="ShortDash2" strokeStyle="#754FFE"/>
                        <StraightLine yValue={10} lineDash="ShortDash2" strokeStyle="#BFAFFC"/>
                        <LineSeries yAccessor={(d) => d.goi3} strokeStyle="#2196f3" />
                        <LineSeries yAccessor={(d) => d.goi2} strokeStyle="#2ca02c" />
                        <LineSeries yAccessor={(d) => d.goi} strokeStyle="#ff7f0e" />


                        <SingleValueTooltip
                            yAccessor={(d) => d.goi3}
                            yLabel="Big Fishes"
                            yDisplayFormat={format(".2f")}
                            valueFill="#2196f3"
                            origin={[8, 16]}
                        />
                        <SingleValueTooltip
                            yAccessor={(d) => d.goi2}
                            yLabel="Medium Fishes"
                            yDisplayFormat={format(".2f")}
                            valueFill="#2ca02c"
                            origin={[8, 32]}
                        />
                        <SingleValueTooltip
                            yAccessor={(d) => d.goi}
                            yLabel="Small Fishes"
                            yDisplayFormat={format(".2f")}
                            valueFill="#ff7f0e"
                            origin={[8, 48]}
                        />


                    </Chart>





                    <CrossHairCursor />
                </ChartCanvas>
            );
        }
    }

    barChartExtents = (data: IOhlc) => {
        return data.volume;
    };

    candleChartExtents = (data: IOhlc) => {
        return [data.high, data.low];
    };

    yEdgeIndicator = (data: IOhlc) => {
        return data.close;
    };

    volumeColor = (data: IOhlc) => {
        return data.close > data.open ? "rgba(38, 166, 154, 0.5)" : "rgba(239, 83, 80, 0.5)";
    };

    volumeSeries = (data: IOhlc) => {
        return data.volume;
    };

    openCloseColor = (data: IOhlc) => {
        return data.close > data.open ? "#26a69a" : "#ef5350";
    };
    
    goiChartExtents = (data: IOhlc) => {
        return [(data.goi>0)?data.goi+5:data.goi, (data.goi2>0)?data.goi2+5:data.goi2, (data.goi3>0)?data.goi3+5:data.goi3];
    };





}

export default withSize({ style: { minHeight: 518 } })(withDeviceRatio()(CandlestickGapChart))


