import React from "react";
import MetaTags from "react-meta-tags";

//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Container, Row} from "reactstrap";

/** import Mini Widget data */
import PatternScores from "./PatternScores";


const TopPatternScores = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Monitor | Jabhoon</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Screening" breadcrumbItem="Top Pattern" />
          <Row>
            <PatternScores />
          </Row>



        </Container>
      </div>
    </React.Fragment>
  );
};

export default TopPatternScores;
