import React, { useState } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CardBody,
  Col,
  Card,
} from "reactstrap";
import classnames from "classnames";
import moment from 'moment';


//SimpleBar
import SimpleBar from "simplebar-react";

//import Charts
import ReactApexChart from "react-apexcharts";

//service
import services from "../../services/services";
import ICandleStick from '../../models/CandleStick/ICandleStick';

//import { CandleStickData } from "../../common/data/candleStick";


const QuoteTransactions = () => {
  const [activeTab, setActiveTab] = useState<string>("1");

  const [topGainerQuotes,setTopGainerQuotes] = useState<ICandleStick[]>([]);
  const [topLoserQuotes,setTopLoserQuotes] = useState<ICandleStick[]>([]);
  const [topAllQuotes,setTopAllQuotes] = useState<ICandleStick[]>([]);

  React.useEffect(() => {
    getTopGainerQuotes();
    getTopLoserQuotes();
    getTopAllQuotes();

  }, []);

  const toggle = (tab: any) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const getTopGainerQuotes = () => {
    services.getTopGainerTickerDailyQuotes().then((response: any) => {
      setTopGainerQuotes(response.data);  
    }).catch((e: Error) => {});
  };

  const getTopLoserQuotes = () => {
    services.getTopLoserTickerDailyQuotes().then((response: any) => {
      setTopLoserQuotes(response.data);  
    }).catch((e: Error) => {});
  };

  const getTopAllQuotes = () => {
    services.getTopAllTickerDailyQuotes().then((response: any) => {
      setTopAllQuotes(response.data);  
    }).catch((e: Error) => {});
  };


  const options: Object = {
    chart: {
      type: "candlestick",
      toolbar: { show: false },
      // sparkline: {
      //   enabled: true
      // },    
      zoom: {
        enabled: false,
        autoScaleYaxis: true,
      }
    },
    /*annotations: {
      xaxis: [{
        x: new Date('2022-02-01 10:00:00').getTime(),
        strokeDashArray: 0,
        borderColor: '#00E396',
        label: {
            borderColor: '#00E396',
            style: {
                color: '#00E396',
                background: '#00E396',
            },
            text: '*',
        }
      }],
      yaxis: [
        {
          y: 14,
          strokeDashArray: 0,
          borderColor: '#EF5350',
          label: {
            borderColor: '#EF5350',
            style: {
              color: '#EF5350',
              background: '#EF5350'
            },
            text: ''
          }
        }
      ]
    },*/
    tooltip: {
      enabled: false,
    },
    xaxis: {
      type: 'datetime',
      // labels: {
      //   formatter: function(val: any) {
      //     return (val).format('MMM DD HH:mm')
      //   }
      // },
      tooltip: {
        enabled: true,
      }
    },
    yaxis: {
      tooltip: {
        enabled: true,
        offsetX: 0,
      },
      opposite: true,
    },
    
    plotOptions: {
        candlestick: {
            colors: {
                upward: '#26A69A',
                downward: '#EF5350'
            }
        },
    },
    colors: ['#26A69A', '#EF5350']
  
  };


  return (
    <React.Fragment>
      <Col xl={12}>
        <Card>
          <div className="card-header align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1 font-size-14">Ticker Daily</h4>
            <div className="flex-shrink-0">
              <Nav
                className="justify-content-end nav-tabs-custom rounded card-header-tabs"
                role="tablist"
              >
                <NavItem>
                  <NavLink
                    to="#"
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Gainer
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    to="#"
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    Loser
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    to="#"
                    className={classnames({ active: activeTab === "3" })}
                    onClick={() => {
                      toggle("3");
                    }}
                  >
                    All
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </div>

          <CardBody className="px-0">
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <SimpleBar
                  className="table-responsive px-3"
                  style={{ maxHeight: "600px" }}
                >
                  <table className="table align-middle table-nowrap table-borderless">
                    <tbody>
                    {(topGainerQuotes || []).map((item: any, key: number) => (
                      <tr key={key}>
                        <td>
                          <div>
                            <h5 className="font-size-12 mb-1">{item["SYMBOL"]}</h5>
                            <p className="text-muted mb-0 font-size-10">{moment(item["REGULAR_MARKET_TIME"]).format('ddd DD HH:mm')}</p>
                          </div>
                        </td>

                        {/* <td>
                          <div className="text-end">
                            <h5 className="font-size-12 mb-0">{item["REGULAR_MARKET_PRICE"]}</h5>
                            <p className="text-muted mb-0 font-size-10">Price</p>
                          </div>
                        </td>      */}

                        <td>
                          <div className="text-end">
                            <h5 className="font-size-12 text-muted mb-0">{item["BID"]}</h5>
                            <p className="text-muted mb-0 font-size-10">Bid</p>
                          </div>
                        </td>

                        <td>
                          <div className="text-end">
                            <h5 className="font-size-12 text-muted mb-0">{item["ASK"]}</h5>
                            <p className="text-muted mb-0 font-size-10">Offer</p>
                          </div>
                        </td>


                        <td>
                          <div className="text-end">
                            <h5 className="font-size-12 mb-0">{item["GAP_BID"]} / {item["GAP_ASK"]}</h5>
                            <p className="text-muted mb-0 font-size-10">G. Bid / Offer</p>
                          </div>
                        </td>

                        <td>
                          <div className="text-end">
                            <h5 className={"font-size-12 mb-0 " + ((item["REGULAR_MARKET_CHANGE_PERCENT"] >= 0) ? 'text-success' : 'text-danger')}>{Number(item["REGULAR_MARKET_CHANGE_PERCENT"]).toFixed(2)} %</h5>
                            <p className="text-muted mb-0 font-size-10">Change</p>
                          </div>
                        </td>

                        <td>
                          <div className="text-end">
                            <h5 className="font-size-12 text-muted mb-0">{(item["REGULAR_TICKER_VOLUME"]/1000000.00).toFixed(2)  + " M"}</h5>
                            <p className="text-muted mb-0 font-size-10">Vol. Change</p>
                          </div>
                        </td>

                        <td>
                          <div className="text-end">                        
                            <ReactApexChart
                              width= "400px"
                              height= "200px"
                              options={options}
                              series={[{ data: [...item["SERIES"]] }]}
                              type="candlestick"
                              className="apex-charts"
                              //dir="ltr"
                            />
                          </div>
                        </td>
                        
                      </tr>
                    ))}       
                    </tbody>
                  </table>
                </SimpleBar>
              </TabPane>

              <TabPane tabId="2">
                <SimpleBar
                  className="table-responsive px-3"
                  style={{ maxHeight: "600px" }}
                >
                  <table className="table align-middle table-nowrap table-borderless">
                    <tbody>
                    {(topLoserQuotes || []).map((item: any, key: number) => (
                      <tr key={key}>
                        <td>
                          <div>
                            <h5 className="font-size-12 mb-1">{item["SYMBOL"]}</h5>
                            <p className="text-muted mb-0 font-size-10">{moment(item["REGULAR_MARKET_TIME"]).format('ddd DD HH:mm')}</p>
                          </div>
                        </td>

                        {/* <td>
                          <div className="text-end">
                            <h5 className="font-size-12 mb-0">{item["REGULAR_MARKET_PRICE"]}</h5>
                            <p className="text-muted mb-0 font-size-10">Price</p>
                          </div>
                        </td>      */}

                        <td>
                          <div className="text-end">
                            <h5 className="font-size-12 text-muted mb-0">{item["BID"]}</h5>
                            <p className="text-muted mb-0 font-size-10">Bid</p>
                          </div>
                        </td>

                        <td>
                          <div className="text-end">
                            <h5 className="font-size-12 text-muted mb-0">{item["ASK"]}</h5>
                            <p className="text-muted mb-0 font-size-10">Offer</p>
                          </div>
                        </td>


                        <td>
                          <div className="text-end">
                            <h5 className="font-size-12 mb-0">{item["GAP_BID"]} / {item["GAP_ASK"]}</h5>
                            <p className="text-muted mb-0 font-size-10">G. Bid / Offer</p>
                          </div>
                        </td>

                        <td>
                          <div className="text-end">
                            <h5 className={"font-size-12 mb-0 " + ((item["REGULAR_MARKET_CHANGE_PERCENT"] >= 0) ? 'text-success' : 'text-danger')}>{Number(item["REGULAR_MARKET_CHANGE_PERCENT"]).toFixed(2)} %</h5>
                            <p className="text-muted mb-0 font-size-10">Change</p>
                          </div>
                        </td>

                        <td>
                          <div className="text-end">
                            <h5 className="font-size-12 text-muted mb-0">{(item["REGULAR_TICKER_VOLUME"]/1000000.00).toFixed(2)  + " M"}</h5>
                            <p className="text-muted mb-0 font-size-10">Vol. Change</p>
                          </div>
                        </td>

                        <td>
                          <div className="text-end">                        
                            <ReactApexChart
                              width= "400px"
                              height= "200px"
                              options={options}
                              series={[{ data: [...item["SERIES"]] }]}
                              type="candlestick"
                              className="apex-charts"
                              //dir="ltr"
                            />
                          </div>
                        </td>
                        
                      </tr>
                    ))}       
                    </tbody>
                  </table>
                </SimpleBar>
              </TabPane>


              <TabPane tabId="3">
                <SimpleBar
                  className="table-responsive px-3"
                  style={{ maxHeight: "600px" }}
                >
                  <table className="table align-middle table-nowrap table-borderless">
                    <tbody>
                    {(topAllQuotes || []).map((item: any, key: number) => (
                      <tr key={key}>
                        <td>
                          <div>
                            <h5 className="font-size-12 mb-1">{item["SYMBOL"]}</h5>
                            <p className="text-muted mb-0 font-size-10">{moment(item["REGULAR_MARKET_TIME"]).format('ddd DD HH:mm')}</p>
                          </div>
                        </td>

                        {/* <td>
                          <div className="text-end">
                            <h5 className="font-size-12 mb-0">{item["REGULAR_MARKET_PRICE"]}</h5>
                            <p className="text-muted mb-0 font-size-10">Price</p>
                          </div>
                        </td>      */}

                        <td>
                          <div className="text-end">
                            <h5 className="font-size-12 text-muted mb-0">{item["BID"]}</h5>
                            <p className="text-muted mb-0 font-size-10">Bid</p>
                          </div>
                        </td>

                        <td>
                          <div className="text-end">
                            <h5 className="font-size-12 text-muted mb-0">{item["ASK"]}</h5>
                            <p className="text-muted mb-0 font-size-10">Offer</p>
                          </div>
                        </td>


                        <td>
                          <div className="text-end">
                            <h5 className="font-size-12 mb-0">{item["GAP_BID"]} / {item["GAP_ASK"]}</h5>
                            <p className="text-muted mb-0 font-size-10">G. Bid / Offer</p>
                          </div>
                        </td>

                        <td>
                          <div className="text-end">
                            <h5 className={"font-size-12 mb-0 " + ((item["REGULAR_MARKET_CHANGE_PERCENT"] >= 0) ? 'text-success' : 'text-danger')}>{Number(item["REGULAR_MARKET_CHANGE_PERCENT"]).toFixed(2)} %</h5>
                            <p className="text-muted mb-0 font-size-10">Change</p>
                          </div>
                        </td>

                        <td>
                          <div className="text-end">
                            <h5 className="font-size-12 text-muted mb-0">{(item["REGULAR_TICKER_VOLUME"]/1000000.00).toFixed(2)  + " M"}</h5>
                            <p className="text-muted mb-0 font-size-10">Vol. Change</p>
                          </div>
                        </td>

                        <td>
                          <div className="text-end">                        
                            <ReactApexChart
                              width= "400px"
                              height= "200px"
                              options={options}
                              series={[{ data: [...item["SERIES"]] }]}
                              type="candlestick"
                              className="apex-charts"
                              //dir="ltr"
                            />
                          </div>
                        </td>
                        
                      </tr>
                    ))}       
                    </tbody>
                  </table>
                </SimpleBar>
              </TabPane>

            </TabContent>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default QuoteTransactions;
