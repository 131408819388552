import React, { useState } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CardBody,
  Col,
  Card,
} from "reactstrap";
import classnames from "classnames";
import moment from 'moment';


//SimpleBar
import SimpleBar from "simplebar-react";

//import Charts
import ReactApexChart from "react-apexcharts";

//service
import services from "../../services/services";
import IQuote from '../../models/QuoteV2/IQuoteV2';

//commons
import commons from '../../utilities/commons';

const BidSizeTransactions = () => {
  const [activeTab, setActiveTab] = useState<string>("3");

  const [topAllQuotes,setTopAllQuotes] = useState<IQuote[]>([]);

  React.useEffect(() => {

    getTopAllQuotesInit();
    const interval = setInterval(() => {
      getTopAllQuotes();
    }, 60000);
    return () => clearInterval(interval);

  }, []);

  const toggle = (tab: any) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const getTopAllQuotesInit = () => {
    services.getTickerBidSizePercentChangeQuotesInit ().then((response: any) => {
      setTopAllQuotes(response.data);  
    }).catch((e: Error) => {});
  };

  const getTopAllQuotes = () => {
    services.getTickerBidSizePercentChangeQuotes ().then((response: any) => {
      setTopAllQuotes(response.data);  
    }).catch((e: Error) => {});
  };
  


  const options: Object = {
    chart: {
      type: "line",
      toolbar: { show: false },
      sparkline: {
        enabled: true
      },
      
    },
    colors: ["#5156be"],
    stroke: {
      curve: "smooth",
      width: 2,
    },
    xaxis: {
      labels: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    tooltip: {
      fixed: {
        enabled: false,
      },
      x: {
        show: false,
      },
      y: {
        title: {
          formatter: function (seriesName: any) {
            return "";
          },
        },
      },
      marker: {
        show: false,
      },
    },
  };

  return (
    <React.Fragment>
      <Col xl={12}>
        <Card>
          <div className="card-header align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1 font-size-14">Top Ticker Cash Flow</h4>
            <div className="flex-shrink-0">
              <Nav
                className="justify-content-end nav-tabs-custom rounded card-header-tabs"
                role="tablist"
              >
                {/* <NavItem>
                  <NavLink
                    to="#"
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Gainer
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    to="#"
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    Loser
                  </NavLink>
                </NavItem> */}
                <NavItem>
                  <NavLink
                    to="#"
                    className={classnames({ active: activeTab === "3" })}
                    onClick={() => {
                      toggle("3");
                    }}
                  >
                    All
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </div>

          <CardBody className="px-0">
            <TabContent activeTab={activeTab}>         

              <TabPane tabId="3">
                <SimpleBar
                  className="table-responsive px-3"
                  style={{ maxHeight: "438px" }}
                >
                  <table className="table align-middle table-nowrap table-borderless">
                    <tbody>
                    {(topAllQuotes || []).map((item: any, key: number) => (
                      <tr key={key}>
                        {/* <td>
                          <div className={"font-size-20 " + ((item["REGULAR_MARKET_CHANGE_PERCENT"] >= 0) ? 'text-success' : 'text-danger')}>
                            <i className={"bx " + ((item["REGULAR_MARKET_CHANGE_PERCENT"] >= 0) ? 'bx-up-arrow-circle' : 'bx-down-arrow-circle') + " d-block"}></i>
                          </div>
                        </td> */}

                        <td>
                          <div>
                            <h5 className="font-size-12 mb-1">{item["SYMBOL"]}</h5>
                            <p className="text-muted mb-0 font-size-10">{moment(item["REGULAR_MARKET_TIME"]).format('ddd DD HH:mm')}</p>
                          </div>
                        </td>

                        <td>
                          <div className="text-end">
                            <h5 className="font-size-12 text-muted mb-0">{item["BID"]}</h5>
                            <p className="text-muted mb-0 font-size-10">Bid</p>
                          </div>
                        </td>

                        <td>
                          <div className="text-end">
                            <h5 className="font-size-12 text-muted mb-0">{item["ASK"]}</h5>
                            <p className="text-muted mb-0 font-size-10">Offer</p>
                          </div>
                        </td>

                        <td>
                          <div className="text-end">
                            <h5 className="font-size-12 text-muted mb-0">{commons.toNumber(item["BID_SIZE"])}</h5>
                            <p className="text-muted mb-0 font-size-10">Bid Size</p>
                          </div>
                        </td>

                        <td>
                          <div className="text-end">
                            <h5 className="font-size-12 text-muted mb-0">{commons.toNumber(item["ASK_SIZE"])}</h5>
                            <p className="text-muted mb-0 font-size-10">Offer Size</p>
                          </div>
                        </td>

                        <td>
                          <div className="text-end">
                            <h5 className={"font-size-12 mb-0 " + ((item["BID_SIZE_CHANGE_PERCENT"] >= 0) ? 'text-success' : 'text-danger')}>{commons.toNumber(item["BID_SIZE_CHANGE_PERCENT"])} %</h5>
                            <p className="text-muted mb-0 font-size-10">Bid Change</p>
                          </div>
                        </td>

                        <td>
                          <div className="text-end">
                            <h5 className={"font-size-12 mb-0 " + ((item["ASK_SIZE_CHANGE_PERCENT"] >= 0) ? 'text-success' : 'text-danger')}>{commons.toNumber(item["ASK_SIZE_CHANGE_PERCENT"])} %</h5>
                            <p className="text-muted mb-0 font-size-10">Offer Change</p>
                          </div>
                        </td>


                        {/* <td>
                          <div className="text-end">
                            <h5 className="font-size-12 mb-0">{item["GAP_BID"]} / {item["GAP_ASK"]}</h5>
                            <p className="text-muted mb-0 font-size-10">G. Bid / Offer</p>
                          </div>
                        </td> */}

                        {/* <td>
                          <div className="text-end">
                            <h5 className="font-size-12 mb-0">{item["GAP_ASK"]}</h5>
                            <p className="text-muted mb-0 font-size-10">G. Offer</p>
                          </div>
                        </td> */}

                        {/* <td>
                          <div className="text-end">
                            <h5 className="font-size-12 text-muted mb-0">{Number(item["TOTAL"])}</h5>
                            <p className="text-muted mb-0 font-size-10">Total</p>
                          </div>
                        </td> */}

                        <td>
                          <div className="text-end">
                            <h5 className={"font-size-12 mb-0 " + ((item["REGULAR_MARKET_CHANGE_PERCENT"] >= 0) ? 'text-success' : 'text-danger')}>{Number(item["REGULAR_MARKET_CHANGE_PERCENT"]).toFixed(2)} %</h5>
                            <p className="text-muted mb-0 font-size-10">Change</p>
                          </div>
                        </td>

                        {/* <td>
                          <div className="text-end">
                            <h5 className="font-size-12 text-muted mb-0">{(item["REGULAR_MARKET_VOLUME"]/1000000.00).toFixed(2)  + " M"}</h5>
                            <p className="text-muted mb-0 font-size-10">Volume</p>
                          </div>
                        </td> */}

                        <td>
                          <div className="text-end">
                            <h5 className="font-size-12 text-muted mb-0">{(item["REGULAR_TICKER_VOLUME"]/1000000.00).toFixed(2)  + " M"}</h5>
                            <p className="text-muted mb-0 font-size-10">Vol. Change</p>
                          </div>
                        </td>
                        
                      </tr>
                    ))}       
                    </tbody>
                  </table>
                </SimpleBar>
              </TabPane>

            </TabContent>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

export default BidSizeTransactions;
