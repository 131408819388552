import React, { useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";

//import countup
import CountUp from "react-countup";

//import Charts
import ReactApexChart from "react-apexcharts";

//service
import services from "../../services/services";
import IQuote from '../../models/Quote/IQuote';

//commons
import commons from '../../utilities/commons';

const MarketMiniWidgets = () => {

  const [marketQuotes,setMarketQuotes] = useState<IQuote[]>([]);

  React.useEffect(() => {
    getMarketQuotes();
    const interval = setInterval(() => {
      getMarketQuotes();
    }, 10000);
    return () => clearInterval(interval);
  }, []);
  
  

  const getMarketQuotes = () => {
    services.getMarketQuotes().then((response: any) => {
      setMarketQuotes(response.data);  
    }).catch((e: Error) => {});
  };
    

  const options: Object = {
    chart: {
      type: "line",
      toolbar: { show: false },
      sparkline: {
        enabled: true
      },
    },
    colors: ["#5156be"],
    stroke: {
      curve: "smooth",
      width: 2,
    },
    xaxis: {
      labels: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    tooltip: {
      fixed: {
        enabled: false,
      },
      x: {
        show: false,
      },
      y: {
        title: {
          formatter: function (seriesName: any) {
            return "";
          },
        },
      },
      marker: {
        show: false,
      },
    },
  };

  return (
    <React.Fragment>
      {(marketQuotes || []).map((item: any, key: number) => (
        <Col xl={3} md={6} key={key}>
          <Card className="card-h-100">
            <CardBody>
              <Row className="align-items-center">
                <Col xs={6}>
                  <div className="mb-3 d-block text-truncate">
                      <span className="text-muted">{item["SYMBOL"]}</span>
                      <span className="ms-2 font-size-13 fw-bolder">{commons.toNumber2Decimal(item["REGULAR_MARKET_PRICE"])}</span>
                  </div>
                  <div className="text-muted mb-0 d-block text-truncate">
                    <span className={"font-size-17 fw-bold " + ((item["REGULAR_MARKET_CHANGE"] >= 0) ? 'text-success' : 'text-danger')}>                   
                    {(item["REGULAR_MARKET_CHANGE"] >= 0) ? "+":""}{Number(item["REGULAR_MARKET_CHANGE"]).toFixed(2)}                  
                    </span>                
                    <span className={"ms-2 badge " + ((item["REGULAR_MARKET_CHANGE"] >= 0) ? 'badge-soft-success text-success' : 'badge-soft-danger text-danger')
                      }>
                      {(item["REGULAR_MARKET_CHANGE"] >= 0) ? "+":""}{Number(item["REGULAR_MARKET_CHANGE_PERCENT"]).toFixed(2)+ "%"}
                    </span>             
                  </div>

                </Col>
                <Col xs={6}>
                  <ReactApexChart
                    
                    options={options}
                    series={[{ data: [...item["SERIES"]] }]}
                    type="line"
                    className="apex-charts"
                    // dir="ltr"
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      ))}
    </React.Fragment>
  );
};

export default MarketMiniWidgets;
