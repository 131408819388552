import React, { useState } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  CardBody,
  Col,
  Card,
} from "reactstrap";
import classnames from "classnames";
import moment from 'moment';


//SimpleBar
import SimpleBar from "simplebar-react";

import IQuote from '../../../models/QuoteV2/IQuoteV2';

//commons
import commons from '../../../utilities/commons';

interface WatchSymbolProps {
  symbol: string;
  quotes: IQuote[];
  quote: IQuote;
}

class QuoteHistorical extends React.Component<WatchSymbolProps> {

  constructor(props: WatchSymbolProps) {
    super(props);
    this.state = {
        
    };
  }



  render() {

    const { symbol, quote, quotes } = this.props;
    return(
    <React.Fragment>
        <Col xl={12}>
          <Card>
            <div className="card-header align-items-center d-flex">
              <h4 className="card-title mb-0 flex-grow-1 font-size-14">{symbol}
              <i className="ms-3 me-2 dripicons-broadcast"></i>
              <span className={"mx-0 "} >{quote["REGULAR_MARKET_PRICE"]}</span>
              <i className="ms-3 me-2 dripicons-pulse"></i>
              <span className={"mx-0 " + ((quote["REGULAR_MARKET_CHANGE_PERCENT"] >= 0) ? 'text-success' : 'text-danger')}>{Number(quote["REGULAR_MARKET_CHANGE_PERCENT"]).toFixed(2)} %</span>
              </h4>
              <div className="flex-shrink-0">
                <Nav
                  className="justify-content-end nav-tabs-custom rounded card-header-tabs"
                  role="tablist"
                >
                </Nav>
              </div>
            </div>
  
            <CardBody className="px-0">
      
  
                <TabPane tabId="1">
                  <SimpleBar
                    className="table-responsive px-3"
                    style={{ maxHeight: "450px" }}
                  >
                    <table className="table align-middle table-nowrap table-borderless">
                      <tbody>
                      {(quotes || []).map((item: any, key: number) => (
                        <tr key={key} 
                        className={(
                          (item["GAP_ASK"] > 0) ? 'table-success' 
                          : ((item["GAP_BID"] > 0) ? 'table-primary'
                          : ((item["BID_SIZE_CHANGE_PERCENT"] >= 100) ? 'table-info'
                          : ((item["GAP_BID"] < 0) ? 'table-danger' 
                          : (item["BID_SIZE_CHANGE_PERCENT"] <= -50) ? 'table-warning':''))))}
                        >
                          <td>
                            <div>
                              <h5 className="font-size-12 mb-1">{moment(item["REGULAR_MARKET_TIME"]).format('DD, HH:mm')}</h5>
                              <p className="text-muted mb-0 font-size-10">Time</p>
                            </div>
                          </td>
  
                          <td>
                            <div className="text-end">
                              <h5 className={"font-size-12 mb-0 " + ((item["REGULAR_MARKET_CHANGE_PERCENT"] >= 0) ? 'text-success' : 'text-danger')}>{Number(item["REGULAR_MARKET_CHANGE_PERCENT"]).toFixed(2)} %</h5>
                              <p className="text-muted mb-0 font-size-10">Change</p>
                            </div>
                          </td>
  
                          <td>
                            <div className="text-end">
                            <h5 className={"font-size-12 mb-0"}><span className={((item["BID_SIZE_CHANGE_PERCENT"] >= 0) ? 'text-success' : 'text-danger')}>{commons.toNumber(item["BID_SIZE_CHANGE_PERCENT"])} %</span> / <span className={((item["ASK_SIZE_CHANGE_PERCENT"] >= 0) ? 'text-success' : 'text-danger')}>{commons.toNumber(item["ASK_SIZE_CHANGE_PERCENT"])} %</span></h5>
                              <p className="text-muted mb-0 font-size-10">Ch. Bid / Offer</p>
                            </div>
                          </td>
  
                          <td>
                            <div className="text-end">
                              <h5 className="font-size-12 mb-0">{item["GAP_BID"]} / {item["GAP_ASK"]}</h5>
                              <p className="text-muted mb-0 font-size-10">G. Bid / Offer</p>
                            </div>
                          </td>
  
                          <td>
                            <div className="text-end">
                              <h5 className="font-size-12 text-muted mb-0">{commons.toNumber(item["BID_SIZE"])}</h5>
                              <p className="text-muted mb-0 font-size-10">Bid Size</p>
                            </div>
                          </td>
  
                          <td>
                            <div className="text-end">
                              <h5 className="font-size-12 text-muted mb-0">{commons.toNumber(item["ASK_SIZE"])}</h5>
                              <p className="text-muted mb-0 font-size-10">Offer Size</p>
                            </div>
                          </td>
  
                          <td>
                            <div className="text-end">
                              <h5 className="font-size-12 mb-0">{item["BID"]} / {item["ASK"]}</h5>
                              <p className="text-muted mb-0 font-size-10">Bid / Offer</p>
                            </div>
                          </td>
  
                          <td>
                            <div className="text-end">
                              <h5 className="font-size-12 text-muted mb-0">{(commons.toNumber2Decimal(item["REGULAR_TICKER_VOLUME"]/1000.00))  + " K"}</h5>
                              <p className="text-muted mb-0 font-size-10">Vol. Change</p>
                            </div>
                          </td>
  
  
                        </tr>
                      ))}       
                      </tbody>
                    </table>
                  </SimpleBar>
                </TabPane>
  
            </CardBody>
          </Card>
        </Col>
      </React.Fragment>
      );
    }
  };
  
export default QuoteHistorical;
