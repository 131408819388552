import { Redirect } from "react-router-dom";

//Dashboard
import Dashboard from "../pages/Dashboard";

//Trading
import Monitor from "../pages/Trading/Monitor";
import Watch from "../pages/Trading/Watch";
import RiskReward from "../pages/Trading/RiskReward";
import StockChart from "../pages/Trading/StockChart";
import CashFlow from "../pages/Trading/CashFlow";

//Fundamental
import GrowthEveryYear from "../pages/Fundamental/GrowthEveryYear";
import GrowthNewHigh from "../pages/Fundamental/GrowthNewHigh";

//Screening
import TopTickers from "../pages/Screening/TopTickers";
import TopPatternScores from "../pages/Screening/TopPatternScores";

//Historical
import QuoteHistorical from "../pages/Historical/QuoteHistorical";

//Authentication pages
import Login from "src/pages/Authentication/Login";
import Logout from "src/pages/Authentication/Logout";
import Register from "src/pages/Authentication/Register";
import userProfile from "src/pages/Authentication/user-profile";

interface RouteProps {
  path: string;
  component: any;
  exact?: boolean;
}

const userRoutes: Array<RouteProps> = [
  //User Profile
  { path: "/profile", component: userProfile },

  //dashboard
  { path: "/dashboard", component: Dashboard },

  //trading
  { path: "/monitor", component: Monitor },
  { path: "/watch", component: Watch },
  { path: "/riskReward", component: RiskReward },
  { path: "/stockChart", component: StockChart },
  { path: "/cashflow", component: CashFlow },

  //fundamental
  { path: "/growthEveryYear", component: GrowthEveryYear },
  { path: "/growthNewHigh", component: GrowthNewHigh },

  //screening
  { path: "/topTickers", component: TopTickers },
  { path: "/topPatternScores", component: TopPatternScores },

  //historical
  { path: "/quoteHistorical", component: QuoteHistorical },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const authRoutes: Array<RouteProps> = [
  //Authentication pages
  { path: "/login", component: Login },
  { path: "/logout", component: Logout },
  { path: "/register", component: Register },
];

export { userRoutes, authRoutes };
