
import http from "../https/http-common";
import ISymbol from "../models/Symbol/ISymbol";
import IQuote from "../models/Quote/IQuote";
import IQuoteV2 from "../models/QuoteV2/IQuoteV2";
import IOhlc from "../models/Ohlc/IOhlc";
import ICandleStick from "../models/CandleStick/ICandleStick";
import IPatternScore from "../models/PatternScore/IPatternScore";
import INetProfitGrowth from "../models/NetProfitGrowth/INetProfitGrowth";
import IWatch from "../models/Watch/IWatch";
import IRiskReward from "../models/RiskReward/IRiskReward";



/** provide service */
const getSymbols = () => {
  return http.get<Array<ISymbol>>(`getSymbol`);
};

const getMarketQuotes = () => {
  return http.get<Array<IQuote>>(`getMarketQuotes`);
};

const getTopGainerPercentChangeQuotesInit = () => {
  return http.get<Array<IQuote>>(`getTopGainerPercentChangeQuotes/1`);
};
const getTopGainerPercentChangeQuotes = () => {
  return http.get<Array<IQuote>>(`getTopGainerPercentChangeQuotes/0`);
};
const getTopLoserPercentChangeQuotesInit = () => {
  return http.get<Array<IQuote>>(`getTopLoserPercentChangeQuotes/1`);
};
const getTopLoserPercentChangeQuotes = () => {
  return http.get<Array<IQuote>>(`getTopLoserPercentChangeQuotes/0`);
};
const getTopAllPercentChangeQuotesInit = () => {
  return http.get<Array<IQuote>>(`getTopAllPercentChangeQuotes/1`);
};
const getTopAllPercentChangeQuotes = () => {
  return http.get<Array<IQuote>>(`getTopAllPercentChangeQuotes/0`);
};

const getTopGainerVolumeChangeQuotesInit = () => {
  return http.get<Array<IQuote>>(`getTopGainerVolumeChangeQuotes/1`);
};
const getTopGainerVolumeChangeQuotes = () => {
  return http.get<Array<IQuote>>(`getTopGainerVolumeChangeQuotes/0`);
};
const getTopLoserVolumeChangeQuotesInit = () => {
  return http.get<Array<IQuote>>(`getTopLoserVolumeChangeQuotes/1`);
};
const getTopLoserVolumeChangeQuotes = () => {
  return http.get<Array<IQuote>>(`getTopLoserVolumeChangeQuotes/0`);
};
const getTopAllVolumeChangeQuotesInit = () => {
  return http.get<Array<IQuote>>(`getTopAllVolumeChangeQuotes/1`);
};
const getTopAllVolumeChangeQuotes = () => {
  return http.get<Array<IQuote>>(`getTopAllVolumeChangeQuotes/0`);
};

const getTopGainerValueChangeQuotesInit = () => {
  return http.get<Array<IQuote>>(`getTopGainerValueChangeQuotes/1`);
};
const getTopGainerValueChangeQuotes = () => {
  return http.get<Array<IQuote>>(`getTopGainerValueChangeQuotes/0`);
};
const getTopLoserValueChangeQuotesInit = () => {
  return http.get<Array<IQuote>>(`getTopLoserValueChangeQuotes/1`);
};
const getTopLoserValueChangeQuotes = () => {
  return http.get<Array<IQuote>>(`getTopLoserValueChangeQuotes/0`);
};
const getTopAllValueChangeQuotesInit = () => {
  return http.get<Array<IQuote>>(`getTopAllValueChangeQuotes/1`);
};
const getTopAllValueChangeQuotes = () => {
  return http.get<Array<IQuote>>(`getTopAllValueChangeQuotes/0`);
};

const getTickerQuotesInit = () => {
  return http.get<Array<IQuote>>(`getTickerQuotes/1`);
};
const getTickerQuotes = () => {
  return http.get<Array<IQuote>>(`getTickerQuotes/0`);
};
const getTopGainerTickerQuotesInit = () => {
  return http.get<Array<IQuote>>(`getTopGainerTickerQuotes/1`);
};
const getTopGainerTickerQuotes = () => {
  return http.get<Array<IQuote>>(`getTopGainerTickerQuotes/0`);
};
const getTopLoserTickerQuotesInit = () => {
  return http.get<Array<IQuote>>(`getTopLoserTickerQuotes/1`);
};
const getTopLoserTickerQuotes = () => {
  return http.get<Array<IQuote>>(`getTopLoserTickerQuotes/0`);
};
const getTopAllTickerQuotesInit = () => {
  return http.get<Array<IQuote>>(`getTopAllTickerQuotes/1`);
};
const getTopAllTickerQuotes = () => {
  return http.get<Array<IQuote>>(`getTopAllTickerQuotes/0`);
};

const getTopGainerTickerDailyQuotes = () => {
  return http.get<Array<ICandleStick>>(`getTopGainerTickerDailyQuotes`);
};
const getTopLoserTickerDailyQuotes = () => {
  return http.get<Array<ICandleStick>>(`getTopLoserTickerDailyQuotes`);
};
const getTopAllTickerDailyQuotes = () => {
  return http.get<Array<ICandleStick>>(`getTopAllTickerDailyQuotes`);
};

const getTopDailyPatternScores = () => {
  return http.get<Array<IPatternScore>>(`getTopDailyPatternScores`);
};


const getTickerBidSizePercentChangeQuotesInit = () => {
  return http.get<Array<IQuoteV2>>(`getTickerBidSizePercentChangeQuotes/1`);
};

const getTickerBidSizePercentChangeQuotes = () => {
  return http.get<Array<IQuoteV2>>(`getTickerBidSizePercentChangeQuotes/0`);
};

const getTickerPortfolioTradings = () => {
  return http.get<Array<IWatch>>(`getTickerPortfolioTradings`);
};

const getQuotesHistoricalSymbol = (symbol: string) => {
  return http.get<Array<IWatch>>(`getQuotesHistoricalSymbol/${symbol}`);
};

const getStockChartSymbol = (symbol: string, interval: string) => {
  return http.get<Array<IOhlc>>(`readStockChartSymbol/${symbol}/${interval}`);
};

const getNetProfitGrowthYears = (period: string, sortBy: string, sortType: string) => {
  return http.get<Array<INetProfitGrowth>>(`getNetProfitGrowthYearsSort/${period}/${sortBy}/${sortType}`);
};
const getNetProfitGrowthNewHigh = (sortBy: string, sortType: string) => {
  return http.get<Array<INetProfitGrowth>>(`getNetProfitGrowthNewHighSort/${sortBy}/${sortType}`);
};


const getRiskReward = (symbol: string) => {
  return http.get<Array<IRiskReward>>(`getRiskRewardRatio/${symbol}`);
};
const getRiskRewardRatio = (symbol: string, risk: number, reward: number) => {
  return http.get<Array<IRiskReward>>(`getRiskRewardRatio/${symbol}/${risk}/${reward}`);
};















/** export service */
const services = {
  getSymbols,
  getMarketQuotes,

  getTopGainerPercentChangeQuotesInit,
  getTopGainerPercentChangeQuotes,
  getTopLoserPercentChangeQuotesInit,
  getTopLoserPercentChangeQuotes,
  getTopAllPercentChangeQuotesInit,
  getTopAllPercentChangeQuotes,

  getTopGainerVolumeChangeQuotesInit,
  getTopGainerVolumeChangeQuotes,
  getTopLoserVolumeChangeQuotesInit,
  getTopLoserVolumeChangeQuotes,
  getTopAllVolumeChangeQuotesInit,
  getTopAllVolumeChangeQuotes,

  getTopGainerValueChangeQuotesInit,
  getTopGainerValueChangeQuotes,
  getTopLoserValueChangeQuotesInit,
  getTopLoserValueChangeQuotes,
  getTopAllValueChangeQuotesInit,
  getTopAllValueChangeQuotes,

  getTopGainerTickerQuotesInit,
  getTopGainerTickerQuotes,
  getTopLoserTickerQuotesInit,
  getTopLoserTickerQuotes,
  getTopAllTickerQuotesInit,
  getTopAllTickerQuotes,

  getTopGainerTickerDailyQuotes,
  getTopLoserTickerDailyQuotes,
  getTopAllTickerDailyQuotes,

  getTopDailyPatternScores,

  getTickerQuotesInit,
  getTickerQuotes,

  getTickerBidSizePercentChangeQuotesInit,
  getTickerBidSizePercentChangeQuotes,

  getTickerPortfolioTradings,
  getQuotesHistoricalSymbol,
  
  getStockChartSymbol,

  getNetProfitGrowthYears,
  getNetProfitGrowthNewHigh,

  getRiskReward,
  getRiskRewardRatio,

  };
  export default services;