export default class OhlcOption {
    date: Date;
    close: number
    high: number;
    low: number;
    open: number;
    volume: number;
    goi: number;
    goi2: number;
    goi3: number;
    constructor(date: Date, close: number, high: number, low: number, open: number, volume: number, goi: number, goi2: number, goi3: number) {
        this.date = date;
        this.close = close;
        this.high = high;
        this.low = low;
        this.open = open;
        this.volume = volume;
        this.goi = goi;
        this.goi2 = goi2;
        this.goi3 = goi3;
      }
}
