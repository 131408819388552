export default class Ohlc {;
    date: Date;
    close: number
    high: number;
    low: number;
    open: number;
    volume: number;
    constructor(date: Date, close: number, high: number, low: number, open: number, volume: number) {
        this.date = date;
        this.close = close;
        this.high = high;
        this.low = low;
        this.open = open;
        this.volume = volume;
      }
}
